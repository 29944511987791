<script setup>
    import { ref } from "vue";
    import { useStore } from "vuex";
    import { useRoute } from 'vue-router';
    import router from "@/router";

    const store = useStore();
    const route = useRoute();
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*/+-])[a-zA-Z0-9!@#$%^&*/+-]{6,20}$/;
    const email = route.params.email;
    const code = route.params.code;

    
    let password = ref(null);
    let confirmation_password = ref(null);
    let isError = ref(false);
    let isPasswordValidation = ref(true);
    let isSubmitted = ref(false);
    
    const checkPasswordValidation = () => {
        isError.value = false;
        isPasswordValidation.value = true;
        if(password.value && confirmation_password.value){
            if(password.value != confirmation_password.value){
                isError.value = true;
            }else if(!isError.value){
                isPasswordValidation.value = regularExpression.test(password.value);
                console.log('isPasswordValidation.value', isPasswordValidation.value);
            }
        }
    } 

    const onSubmit = () => {
        isError.value = false;
        isSubmitted.value = true;
        const data = {
            email: email,
            code: code,
            password: password.value,
        };
        store.dispatch("resetPassword", data)
        .then( async (res) => {
            console.log('res', res);
            await router.push("/");
        })
        .catch((e) => {
            console.log("e", e);
            isSubmitted.value = false;
        });
    };
</script>

<template>  
    <div class="auth-container col-lg-5 col-md-9 m-auto">
        <div class="auth-card">
            <div class="text-center">
                <img class="logo" src="@/assets/images/logo/logo.png">
            </div>
            <p class="welcome-message mt-24">WELCOME</p>
            <p class="second-welcome-message">Reset Password</p>
            <div class="form-floating mb-3">
                <input minlength="8" maxlength="20" @input="checkPasswordValidation" v-model="password" type="password" class="form-control" :class="{'is-invalid' : isError}" id="password" placeholder="name@example.com"/>
                <label for="password">Password</label>
            </div>
            <div class="form-floating">
                <input minlength="8" maxlength="20" @input="checkPasswordValidation" v-model="confirmation_password" type="password" class="form-control" :class="{'is-invalid' : isError}" id="confirmationPassword" placeholder="Password" />
                <label for="confirmationPassword">Re-enter Password</label>
            </div>
            <div class="text-left  " style="margin-top: 8px;margin-left:22px;width:372px">

                <span v-if="isError" class="auth-notes error-login-message">
                    Passwords not matching, please check again.
                </span>

                <span v-if="!isError" class="auth-notes-two" :class="{'error-login-message' : !isPasswordValidation}">
                   <b> Password must include:</b> 8-20 characters, a lowercase letter, 
                    an uppercase letter, a number and a special character.
                </span>

            </div>
            <button @click="onSubmit" :disabled="!password || !confirmation_password || isSubmitted || isError || !isPasswordValidation" type="button" class="form-action-btn btn btn-success mt-40">
                Reset
                <span v-if="isSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
            <p class="forth-welcome-message mt-24">Want to login instead!</p>
            <router-link class="none-underline" to="/login"><p class="login-text mt-24">LOGIN</p></router-link>
        </div>
    </div>
</template>